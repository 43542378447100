/* --------------------------------------------------------
   AppLayout.css
   --------------------------------------------------------
   - Fixed header at the top (48px tall)
   - Fixed sidebar on the left (240px wide, starting below header)
   - Main content area offset by header and sidebar on desktop
--------------------------------------------------------- */

/* Base Variables */
:root {
  --space-sm: 8px;
  --space-md: 16px;
  --radius: 12px;
  --transition-fast: 0.2s;
  --background-color: #000;    /* Overall background */
  --text-color: #fff;          /* Overall text color */
  --hover-overlay: rgba(255, 255, 255, 0.1); /* Hover overlay */
}

/* Outer container for the entire layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

/* 1) Fixed Header (48px tall) */
/* (The header itself is styled in its own file; here we reserve space) */
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  z-index: 1000;
}

/* 2) Layout Body */
/* Container for sidebar and main content, offset below header */
.layout-body {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 48px;  /* Offset for header height */
  overflow: hidden;
}

/* 3) Fixed Sidebar */
/* Sidebar is fixed to left and starts at top:48px */
.sidebar {
  position: fixed;
  top: 48px;
  left: 0;
  bottom: 0;
  width: 240px;      /* Sidebar width */
  background-color: #222; /* Adjust as needed */
  overflow-y: auto;
  z-index: 999;
  transition: transform 0.3s ease;
}

/* When collapsed (e.g. on mobile) */
.sidebar.closed {
  transform: translateX(-100%);
}

/* 4) Main Content Area */
/* Content area offset by header and sidebar */
.content-area {
  flex: 1;
  margin-top: 48px;    /* Offset by header */
  margin-left: 240px;  /* Offset by sidebar on desktop */
  padding: 1rem;
  background-color: var(--background-color);
  overflow-y: auto;
  transition: margin-left 0.3s ease;
}

/* Optional: When sidebar is collapsed, remove left margin */
.content-area.collapsed {
  margin-left: 0;
}

/* 5) Notifications Panel (if used) */
.notifications-panel {
  position: fixed;
  top: 48px;
  right: 0;
  width: 320px;
  max-height: calc(100vh - 48px);
  background-color: var(--background-color);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  z-index: 10;
  overflow-y: auto;
}

/* 6) Responsive Adjustments */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);  /* Hidden by default on mobile */
  }
  .sidebar.open {
    transform: translateX(0);      /* Slide in when open */
  }
  .content-area {
    margin-left: 0;                /* Full width on mobile */
  }
  .notifications-panel {
    width: 100%;
  }
}

.menu.cross {
  --length: 24;
  --offset: -38;
}

.menu.cross button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 48px;  /* Increased from 36px */
  height: 48px; /* Increased from 36px */
}

.menu.cross input {
  display: none;
}

.menu.cross path {
  fill: none;
  stroke: #fff;
  stroke-width: 4; /* Increased from 3 */
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
  transition: all .8s cubic-bezier(.645, .045, .355, 1);
}

.menu.cross circle {
  fill: rgb(255 255 255 / 20%);
  opacity: 0;
  transition: opacity 0.3s;
}

.menu.cross button:hover circle {
  opacity: 1;
}

.menu--1 .line--1,
.menu--1 .line--3 {
  --total-length: 126.64183044433594;
}

.menu--1 .line--2 {
  --total-length: 70;
}

/* Changed from input:checked to .open for the class-based approach */
.menu--1.open .line--1,
.menu--1.open .line--3 {
  --length: 22.627416998;
  --offset: -94.1149185097;
}

.menu--1.open .line--2 {
  --length: 0;
  --offset: -50;
}
/* ============================== */
/* DECISION TREE MODAL OVERRIDES  */
/* ============================== */

/* A more rounded, blurred, glassy modal look */
.meditation-decision-tree-modal {
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    overflow: hidden;
    animation: fadeIn 0.4s ease;
    transition: opacity 0.2s ease, transform 0.2s ease;
    color: white;
    font-family: var(--font-body, system-ui, -apple-system, BlinkMacSystemFont, sans-serif);
  }

  .meditation-decision-tree-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .meditation-decision-tree-overlay.active {
    opacity: 1;
  }
  
  /* Header with back and close aligned left/right */
  .meditation-decision-tree-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border-bottom: none; /* remove the bottom border */
    margin-bottom: 0;    /* remove default margin */
  }
  
  /* Back button aligned left */
  .meditation-decision-tree-back {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 9999px;
    background: rgba(255, 255, 255, 0.08);
    border: none;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  .meditation-decision-tree-back:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  /* Close button aligned right */
  .meditation-decision-tree-close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 9999px;
    background: rgba(255, 255, 255, 0.08);
    border: none;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  .meditation-decision-tree-close:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  /* Prompt centered, with a bit more spacing */
  .meditation-decision-tree-content {
    padding: 0 20px 20px;
    text-align: center;
  }
  .meditation-decision-tree-prompt {
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.95);
    margin: 0 auto 24px;
    line-height: 1.4;
  }
  
  /* Options as large pill buttons in a single row or two columns */
  .meditation-decision-tree-options {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap; /* if more than 2, they wrap */
    margin: 0 auto;
    padding: 0;
  }
  
/* Base style for each option pill */
.meditation-decision-tree-pill {
    min-width: 140px;
    padding: 12px 18px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 9999px;
    /* Use a CSS variable for the background so it can be set dynamically */
    background: var(--pill-bg, rgba(255, 255, 255, 0.1));
    border: none;
    color: white;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease, filter 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .meditation-decision-tree-pill:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  }
  
  /* When selected, apply the neonish effect but do NOT override the background */
  .meditation-decision-tree-pill.selected {
    filter: saturate(2) brightness(1.4) contrast(1.2);
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.4);
  }
  
  /* Icons inside the pill */
  .meditation-decision-tree-pill-icon {
    font-size: 16px;
  }
  
  /* The smaller “selected options” row at the bottom */
  .meditation-decision-tree-selected {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    margin-top: 24px;
  }
  
  /* Each selected option as a smaller pill */
  .meditation-decision-tree-selected-option {
    padding: 6px 12px;
    border-radius: 9999px;
    background: rgba(255, 255, 255, 0.08);
    color: white;
    font-size: 13px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
  .meditation-decision-tree-selected-option.main-category {
    /* If you want the main category to have a stronger glow: */
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
  }
  
  /* The icon in the selected pill */
  .meditation-decision-tree-selected-icon {
    font-size: 15px;
  }
  
  /* Progress bar at the bottom */
  .meditation-decision-tree-progress {
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.1);
    margin-top: 24px;
    position: relative;
    border-radius: 1px;
    overflow: hidden;
  }
  .meditation-decision-tree-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 1px;
    transition: width 0.4s ease;
    background: linear-gradient(90deg, #a18cd1, #fbc2eb); /* example gradient */
  }
  
  /* Fade in animation for the modal */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to   { opacity: 1; transform: translateY(0); }
  }
  
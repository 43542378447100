/* Pause App - 2025 Edition */
/* Modern, minimal, mobile-first meditation experience */

:root {
  /* Core color palette - Monochromatic with accent */
  --color-black: #000000;
  --color-dark: #111111;
  --color-surface: #181818;
  --color-surface-elevated: #222222;
  --color-border: rgba(255, 255, 255, 0.08);
  --color-disabled: rgba(255, 255, 255, 0.2);
  
  /* Accent spectrum */
  --color-primary: #7d12ff;  /* Violet primary */
  --color-primary-muted: rgba(125, 18, 255, 0.7);
  --color-primary-subtle: rgba(125, 18, 255, 0.12);
  --color-secondary: #12ddff; /* Cyan secondary accent */
  
  /* Text colors */
  --color-text-primary: rgba(255, 255, 255, 0.92);
  --color-text-secondary: rgba(255, 255, 255, 0.65);
  --color-text-tertiary: rgba(255, 255, 255, 0.45);
  
  /* Core spacing units (based on 4px grid) */
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 24px;
  --space-6: 32px;
  --space-7: 48px;
  --space-8: 64px;
  
  /* Radius and effects */
  --radius-sm: 8px;
  --radius-md: 16px;
  --radius-lg: 24px;
  --radius-xl: 32px;
  --radius-pill: 9999px;
  
  /* Depth */
  --shadow-subtle: 0 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-medium: 0 4px 12px rgba(0, 0, 0, 0.08);
  --shadow-prominent: 0 12px 32px rgba(0, 0, 0, 0.12);
  
  /* Font stacks */
  --font-display: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  --font-body: 'SF Pro Text', system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  
  /* Typography */
  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-base: 1rem;
  --text-lg: 1.125rem;
  --text-xl: 1.25rem;
  --text-2xl: 1.5rem;
  --text-3xl: 1.875rem;
  --text-4xl: 2.25rem;
  
  /* Animation speeds */
  --transition-fast: 0.15s;
  --transition-base: 0.25s;
  --transition-slow: 0.35s;
  
  /* Container width */
  --container-width: 100%;
  --container-width-sm: 540px;
  --container-width-md: 720px;
  --container-width-lg: 960px;
  
  /* Z-indices */
  --z-base: 1;
  --z-above: 10;
  --z-modal: 100;
  --z-overlay: 1000;
}

/* Global resets & base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: var(--color-black);
  color: var(--color-text-primary);
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

/* Typography system */
h1, .h1 {
  font-family: var(--font-display);
  font-size: var(--text-4xl);
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.1;
}

h2, .h2 {
  font-family: var(--font-display);
  font-size: var(--text-3xl);
  font-weight: 700;
  letter-spacing: -0.015em;
  line-height: 1.2;
}

h3, .h3 {
  font-family: var(--font-display);
  font-size: var(--text-2xl);
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.25;
}

h4, .h4 {
  font-family: var(--font-display);
  font-size: var(--text-xl);
  font-weight: 600;
  line-height: 1.3;
}

p {
  margin-bottom: var(--space-4);
}

small, .text-sm {
  font-size: var(--text-sm);
}

.text-xs {
  font-size: var(--text-xs);
}

.text-lg {
  font-size: var(--text-lg);
}

.text-xl {
  font-size: var(--text-xl);
}

/* Text colors */
.text-primary {
  color: var(--color-text-primary);
}

.text-secondary {
  color: var(--color-text-secondary);
}

.text-tertiary {
  color: var(--color-text-tertiary);
}

.text-accent {
  color: var(--color-primary);
}

/* Font weights */
.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

/* Meditation App Container */
.meditation-app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  background: var(--color-black);
  overflow-x: hidden;
}

/* App header */
.app-header {
  position: sticky;
  top: 0;
  z-index: var(--z-above);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-4) var(--space-5);
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-bottom: 1px solid var(--color-border);
}

.app-logo {
  display: flex;
  align-items: center;
  gap: var(--space-2);
}

.app-logo-icon {
  width: 32px;
  height: 32px;
  background: linear-gradient(135deg, var(--color-primary), var(--color-secondary));
  border-radius: var(--radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-logo-text {
  font-family: var(--font-display);
  font-weight: 600;
  font-size: var(--text-lg);
  color: var(--color-text-primary);
}

.header-actions {
  display: flex;
  align-items: center;
  gap: var(--space-3);
}

.icon-button {
  width: 40px;
  height: 40px;
  border-radius: var(--radius-pill);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-primary);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.icon-button:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

/* Content area */
.meditation-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  overflow-x: hidden;
}

/* Progress bar */
.progress-container {
  padding: var(--space-5) var(--space-5) var(--space-4);
  position: relative;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 var(--space-4);
}

.progress-bar::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 2px;
  background-color: var(--color-border);
  transform: translateY(-50%);
  z-index: 1;
}

.progress-bar::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  background-color: var(--color-primary);
  transform: translateY(-50%);
  z-index: 2;
  width: calc((100% / 5) * (var(--current-step) - 1));
  transition: width var(--transition-base) ease;
}

.progress-step {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-2);
}

.step-indicator {
  width: 32px;
  height: 32px;
  border-radius: var(--radius-pill);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-sm);
  font-weight: 500;
  color: var(--color-text-secondary);
  background-color: var(--color-surface);
  border: 1px solid var(--color-border);
  transition: all var(--transition-base);
}

.step-indicator.active {
  background-color: var(--color-primary);
  color: white;
  transform: scale(1.1);
  box-shadow: 0 0 0 4px var(--color-primary-subtle);
}

.step-indicator.completed {
  background-color: var(--color-surface);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.step-name {
  font-size: var(--text-xs);
  font-weight: 500;
  color: var(--color-text-tertiary);
  transition: color var(--transition-fast);
}

.step-name.active {
  color: var(--color-text-primary);
}

/* Step container */
.step-container {
  padding: var(--space-4) var(--space-5) var(--space-6);
  margin-bottom: var(--space-6);
  display: flex;
  flex-direction: column;
  gap: var(--space-5);
  animation: fadeSlideUp var(--transition-base);
}

.step-header {
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--space-2);
}

.step-title {
  font-family: var(--font-display);
  font-size: var(--text-2xl);
  font-weight: 700;
  color: var(--color-text-primary);
  letter-spacing: -0.01em;
}

.step-description {
  font-size: var(--text-base);
  color: var(--color-text-secondary);
  max-width: 580px;
  margin: 0 auto;
}

/* Form elements */
.form-group {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  width: 100%;
}

.input-label {
  font-size: var(--text-sm);
  font-weight: 500;
  color: var(--color-text-secondary);
}

.textarea {
  width: 100%;
  min-height: 180px;
  padding: var(--space-4);
  border-radius: var(--radius-md);
  background-color: var(--color-surface-elevated);
  border: 1px solid var(--color-border);
  color: var(--color-text-primary);
  font-family: var(--font-body);
  font-size: var(--text-base);
  resize: vertical;
  transition: border-color var(--transition-fast);
}

.textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-subtle);
}

.textarea::placeholder {
  color: var(--color-text-tertiary);
}

/* Button styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-3) var(--space-5);
  border-radius: var(--radius-pill);
  font-size: var(--text-base);
  font-weight: 500;
  transition: all var(--transition-fast);
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  border: none;
}

.btn-lg {
  padding: var(--space-4) var(--space-6);
  font-size: var(--text-lg);
}

.btn-sm {
  padding: var(--space-2) var(--space-4);
  font-size: var(--text-sm);
}

.btn-primary {
  background-color: var(--color-primary);
  color: white;
}

.btn-primary:hover {
  background-color: var(--color-primary-muted);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(125, 18, 255, 0.3);
}

.btn-secondary {
  background-color: transparent;
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
}

.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.btn-text {
  background-color: transparent;
  color: var(--color-primary);
  padding: var(--space-2) var(--space-3);
}

.btn-text:hover {
  background-color: var(--color-primary-subtle);
}

.btn-disabled,
.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.btn-icon {
  display: inline-flex;
  margin-right: var(--space-2);
}

.btn-icon-right {
  margin-right: 0;
  margin-left: var(--space-2);
}

/* Voice selection */
.voice-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: var(--space-4);
  width: 100%;
}

.voice-card {
  padding: var(--space-4);
  border-radius: var(--radius-md);
  background-color: var(--color-surface-elevated);
  border: 1px solid var(--color-border);
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  gap: var(--space-3);
}

.voice-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-medium);
  border-color: rgba(255, 255, 255, 0.15);
}

.voice-card.selected {
  border-color: var(--color-primary);
  background-color: rgba(125, 18, 255, 0.08);
}

.voice-avatar {
  width: 48px;
  height: 48px;
  border-radius: var(--radius-pill);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-lg);
  background-color: var(--color-primary-subtle);
  color: var(--color-primary);
  flex-shrink: 0;
}

.voice-female {
  background-color: rgba(255, 105, 180, 0.1);
  color: #ff69b4;
}

.voice-male {
  background-color: rgba(0, 191, 255, 0.1);
  color: #00bfff;
}

.voice-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.voice-name {
  font-weight: 600;
  color: var(--color-text-primary);
}

.voice-description {
  font-size: var(--text-sm);
  color: var(--color-text-secondary);
}

.voice-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: var(--radius-pill);
  border: 1px solid var(--color-border);
  flex-shrink: 0;
  transition: all var(--transition-fast);
}

.voice-card.selected .voice-check {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

/* Music selection */
.music-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: var(--space-4);
  width: 100%;
}

.music-card {
  aspect-ratio: 1 / 1;
  border-radius: var(--radius-md);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.music-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-medium);
}

.music-card.selected {
  box-shadow: 0 0 0 2px var(--color-primary), var(--shadow-medium);
}

.music-bg {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
  transition: filter var(--transition-fast);
}

.music-card:hover .music-bg {
  filter: brightness(0.85);
}

.music-content {
  position: absolute;
  inset: 0;
  padding: var(--space-3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.music-name {
  font-weight: 600;
  color: white;
  margin-bottom: var(--space-1);
}

.music-mood {
  font-size: var(--text-xs);
  color: rgba(255, 255, 255, 0.8);
}

.music-selected-icon {
  position: absolute;
  top: var(--space-3);
  right: var(--space-3);
  width: 24px;
  height: 24px;
  border-radius: var(--radius-pill);
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* Audio mixer */
.mixer-controls {
  display: flex;
  flex-direction: column;
  gap: var(--space-6);
  width: 100%;
}

.mixer-group {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
}

.mixer-row {
  display: flex;
  align-items: center;
  gap: var(--space-3);
}

.mixer-label {
  flex: 0 0 100px;
  font-size: var(--text-sm);
  font-weight: 500;
  color: var(--color-text-secondary);
}

.mixer-slider {
  flex: 1;
  position: relative;
}

.mixer-slider-input {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: var(--color-surface-elevated);
  border-radius: var(--radius-pill);
  outline: none;
}

.mixer-slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: var(--radius-pill);
  background: var(--color-primary);
  cursor: pointer;
  box-shadow: 0 0 0 4px var(--color-primary-subtle);
  transition: all var(--transition-fast);
}

.mixer-slider-input::-webkit-slider-thumb:hover {
  transform: scale(1.1);
}

.mixer-slider-input::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: var(--radius-pill);
  background: var(--color-primary);
  cursor: pointer;
  box-shadow: 0 0 0 4px var(--color-primary-subtle);
  transition: all var(--transition-fast);
}

.mixer-slider-input::-moz-range-thumb:hover {
  transform: scale(1.1);
}

.mixer-value {
  min-width: 40px;
  text-align: center;
  font-size: var(--text-sm);
  font-weight: 500;
  color: var(--color-text-primary);
  flex-shrink: 0;
}

.mixer-labels {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-1);
}

.mixer-label-text {
  font-size: var(--text-xs);
  color: var(--color-text-tertiary);
}

/* Advanced options */
.advanced-options {
  background-color: var(--color-surface-elevated);
  border-radius: var(--radius-md);
  border: 1px solid var(--color-border);
  padding: var(--space-4);
  margin-top: var(--space-3);
}

.advanced-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-4);
}

.advanced-title {
  font-size: var(--text-base);
  font-weight: 600;
  color: var(--color-text-primary);
}

.advanced-toggle {
  font-size: var(--text-sm);
  color: var(--color-primary);
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--space-1);
}

.advanced-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: var(--space-4);
}

.option-card {
  padding: var(--space-3);
  border-radius: var(--radius-sm);
  background-color: rgba(255, 255, 255, 0.03);
  border: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  gap: var(--space-3);
}

.option-check {
  flex-shrink: 0;
}

.option-label {
  font-size: var(--text-sm);
  color: var(--color-text-secondary);
}

/* Audio player */
.audio-player {
  width: 100%;
  padding: var(--space-5);
  background-color: var(--color-surface-elevated);
  border-radius: var(--radius-md);
  display: flex;
  flex-direction: column;
  gap: var(--space-5);
}

.player-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-4);
}

.play-button {
  width: 64px;
  height: 64px;
  border-radius: var(--radius-pill);
  background: linear-gradient(135deg, var(--color-primary), #9b4dff);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: all var(--transition-fast);
}

.play-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(125, 18, 255, 0.3);
}

.player-timeline {
  width: 100%;
  position: relative;
}

.timeline-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: var(--radius-pill);
  outline: none;
}

.timeline-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: var(--radius-pill);
  background: white;
  cursor: pointer;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1);
}

.timeline-labels {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-2);
}

.timeline-time {
  font-size: var(--text-xs);
  color: var(--color-text-secondary);
}

.player-actions {
  display: flex;
  justify-content: center;
  gap: var(--space-3);
}

.player-action-button {
  width: 40px;
  height: 40px;
  border-radius: var(--radius-pill);
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.player-action-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Action buttons container */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
}

/* Final actions */
.download-button {
  width: 100%;
  padding: var(--space-4);
  border-radius: var(--radius-pill);
  background: linear-gradient(135deg, var(--color-primary), #9b4dff);
  color: white;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-2);
  transition: all var(--transition-fast);
}

.download-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(125, 18, 255, 0.2);
}

.reset-button {
  width: 100%;
  padding: var(--space-4);
  border-radius: var(--radius-pill);
  background-color: transparent;
  color: var(--color-text-primary);
  font-weight: 500;
  border: 1px solid var(--color-border);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-2);
  transition: all var(--transition-fast);
}

.reset-button:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Step navigation */
.step-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-4) var(--space-5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-top: 1px solid var(--color-border);
  z-index: var(--z-above);
}

.nav-button {
  padding: var(--space-3) var(--space-5);
  border-radius: var(--radius-pill);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  transition: all var(--transition-fast);
}

.back-button {
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--color-text-secondary);
  border: none;
  cursor: pointer;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--color-text-primary);
}

.next-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  cursor: pointer;
}

.next-button:hover {
  background-color: var(--color-primary-muted);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(125, 18, 255, 0.2);
}

next-button:disabled {
    opacity: 0.5;
    background-color: rgba(125, 18, 255, 0.5);
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  

  
  
  /* Toast notifications */
  .toast-container {
    position: fixed;
    top: var(--space-5);
    right: var(--space-5);
    z-index: var(--z-overlay);
    display: flex;
    flex-direction: column;
    gap: var(--space-3);
    pointer-events: none;
  }
  
  .toast {
    padding: var(--space-3) var(--space-4);
    border-radius: var(--radius-md);
    background-color: var(--color-surface-elevated);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-prominent);
    display: flex;
    align-items: center;
    gap: var(--space-3);
    max-width: 320px;
    animation: toastSlideIn var(--transition-base);
    pointer-events: auto;
  }
  
  .toast-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .toast-icon.success {
    color: #10b981;
  }
  
  .toast-icon.error {
    color: #ef4444;
  }
  
  .toast-icon.info {
    color: #3b82f6;
  }
  
  .toast-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }
  
  .toast-title {
    font-weight: 600;
    font-size: var(--text-sm);
    color: var(--color-text-primary);
  }
  
  .toast-message {
    font-size: var(--text-xs);
    color: var(--color-text-secondary);
  }
  
  .toast-close {
    width: 20px;
    height: 20px;
    border-radius: var(--radius-pill);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--color-text-tertiary);
    border: none;
    cursor: pointer;
    flex-shrink: 0;
    transition: all var(--transition-fast);
  }
  
  .toast-close:hover {
    color: var(--color-text-primary);
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /* Create Step - Specific Styles */
  .greeting-title {
    font-family: var(--font-display);
    font-size: var(--text-2xl);
    font-weight: 700;
    color: var(--color-text-primary);
    text-align: center;
    margin-bottom: var(--space-1);
  }
  
  .greeting-subtitle {
    font-size: var(--text-base);
    font-weight: 500;
    color: var(--color-text-secondary);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: var(--space-5);
  }
  
  .prompt-toolbar {
    background-color: var(--color-surface-elevated);
    border-radius: var(--radius-md);
    padding: var(--space-4);
    border: 1px solid var(--color-border);
    margin-bottom: var(--space-5);
    transition: all var(--transition-fast);
  }
  
  .prompt-toolbar:focus-within {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-primary-subtle);
  }
  
  .toolbar-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-3);
  }
  
  .toolbar-left, .toolbar-right {
    display: flex;
    gap: var(--space-2);
  }
  
  .toolbar-button {
    width: 36px;
    height: 36px;
    border-radius: var(--radius-pill);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--color-text-secondary);
    border: none;
    cursor: pointer;
    transition: all var(--transition-fast);
  }
  
  .toolbar-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-text-primary);
  }
  
  .toolbar-button.active {
    color: var(--color-primary);
    background-color: var(--color-primary-subtle);
  }
  
  .recording-indicator {
    display: flex;
    align-items: center;
    gap: var(--space-2);
    font-size: var(--text-sm);
    font-weight: 500;
    color: #ef4444;
    animation: pulse 2s infinite;
  }
  
  .recording-dot {
    width: 10px;
    height: 10px;
    border-radius: var(--radius-pill);
    background-color: #ef4444;
  }
  
  /* VoiceStep - Specific Styles */
  .voice-preview {
    background-color: var(--color-surface-elevated);
    border-radius: var(--radius-md);
    padding: var(--space-4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-4);
    margin-top: var(--space-5);
    border: 1px solid var(--color-border);
  }
  
  .voice-preview-info {
    display: flex;
    align-items: center;
    gap: var(--space-3);
  }
  
  .voice-preview-avatar {
    width: 40px;
    height: 40px;
    border-radius: var(--radius-pill);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-base);
    background-color: var(--color-primary-subtle);
    color: var(--color-primary);
  }
  
  .voice-preview-name {
    font-weight: 600;
    color: var(--color-text-primary);
  }
  
  /* MusicSelection - Specific Styles */
  .music-filter {
    display: flex;
    overflow-x: auto;
    margin-bottom: var(--space-5);
    padding-bottom: var(--space-2);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .music-filter::-webkit-scrollbar {
    display: none;
  }
  
  .filter-button {
    padding: var(--space-2) var(--space-4);
    border-radius: var(--radius-pill);
    background-color: transparent;
    color: var(--color-text-secondary);
    border: 1px solid var(--color-border);
    font-size: var(--text-sm);
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
    transition: all var(--transition-fast);
  }
  
  .filter-button:hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: var(--color-text-primary);
  }
  
  .filter-button.active {
    background-color: var(--color-primary);
    color: white;
    border-color: var(--color-primary);
  }
  
  .music-preview {
    display: flex;
    align-items: center;
    gap: var(--space-3);
    margin-top: var(--space-5);
    padding: var(--space-4);
    border-radius: var(--radius-md);
    background-color: var(--color-surface-elevated);
    border: 1px solid var(--color-border);
  }
  
  .music-preview-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-1);
  }
  
  .music-preview-name {
    font-weight: 600;
    color: var(--color-text-primary);
  }
  
  .music-preview-mood {
    font-size: var(--text-sm);
    color: var(--color-text-secondary);
  }
  
  .music-preview-play {
    width: 40px;
    height: 40px;
    border-radius: var(--radius-pill);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    color: white;
    border: none;
    cursor: pointer;
    flex-shrink: 0;
    transition: all var(--transition-fast);
  }
  
  .music-preview-play:hover {
    background-color: var(--color-primary-muted);
    transform: scale(1.05);
  }
  
  /* ListenStep - Final actions */
  .share-button {
    width: 100%;
    padding: var(--space-4);
    border-radius: var(--radius-pill);
    background-color: var(--color-primary-subtle);
    color: var(--color-primary);
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--space-2);
    transition: all var(--transition-fast);
  }
  
  .share-button:hover {
    background-color: rgba(125, 18, 255, 0.2);
  }
  
  /* Animations */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeSlideUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
  }
  
  @keyframes toastSlideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Media queries */
  @media (max-width: 768px) {
    :root {
      --space-5: 20px;
      --space-6: 28px;
      --space-7: 40px;
      --space-8: 56px;
    }
    
    .voice-grid {
      grid-template-columns: 1fr;
    }
    
    .advanced-content {
      grid-template-columns: 1fr;
    }
    
    .mixer-row {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--space-2);
    }
    
    .mixer-label {
      flex: none;
      width: 100%;
    }
    
    .mixer-slider {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    :root {
      --space-4: 14px;
      --space-5: 18px;
      --text-2xl: 1.35rem;
      --text-lg: 1.05rem;
    }
    
    .music-grid {
      grid-template-columns: 1fr 1fr;
    }
    
    .player-actions {
      flex-wrap: wrap;
      justify-content: center;
    }
    
    .step-navigation {
      padding: var(--space-3) var(--space-4);
    }
    
    .nav-button {
      padding: var(--space-2) var(--space-4);
    }
  }
  
  /* Dark mode overrides */
  @media (prefers-color-scheme: dark) {
    :root {
      --color-border: rgba(255, 255, 255, 0.08);
    }
  }
  
  /* Light mode (if user specifically selects it) */
  .light-theme {
    --color-black: #ffffff;
    --color-dark: #f5f5f5;
    --color-surface: #f8f8f8;
    --color-surface-elevated: #ffffff;
    --color-border: rgba(0, 0, 0, 0.1);
    --color-disabled: rgba(0, 0, 0, 0.2);
    
    --color-text-primary: rgba(0, 0, 0, 0.9);
    --color-text-secondary: rgba(0, 0, 0, 0.65);
    --color-text-tertiary: rgba(0, 0, 0, 0.45);
    
    --shadow-subtle: 0 1px 2px rgba(0, 0, 0, 0.04);
    --shadow-medium: 0 4px 12px rgba(0, 0, 0, 0.04);
    --shadow-prominent: 0 12px 32px rgba(0, 0, 0, 0.06);
  }

  /* Category pills */
.category-rows {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-bottom: 24px;
}

.category-row {
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
}

.category-pills {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding: 8px 0;
  margin-bottom: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.category-pills::-webkit-scrollbar {
  display: none;
}

.category-pill {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.08);
  border: none;
  border-radius: 9999px;
  color: white;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.category-pill:hover {
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.category-pill.active {
  background-color: var(--pill-bg-color, rgba(255, 255, 255, 0.1));
  color: var(--pill-color, white);
  box-shadow: 0 0 12px var(--pill-bg-color, rgba(255, 255, 255, 0.1));
}

.pill-icon {
  font-size: 16px;
}

/* Section header */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 16px;
  width: 100%;
}

.section-header h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text-primary);
  margin: 0;
}

.see-all {
  color: var(--color-primary);
  font-size: 14px;
  text-decoration: none;
}

/* Tree section for decision tree */
.tree-section {
  width: 100%;
  margin-top: 24px;
  animation: fadeIn 0.3s ease;
}

.tree-prompt {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin-bottom: 24px;
}

.tree-options-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 16px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.tree-option {
  background-color: #1c1c1e;
  border: 1px solid var(--color-border, rgba(255,255,255,0.1));
  border-radius: 16px;
  padding: 16px;
  text-align: center;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.tree-option:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

/* Selected options display */
.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
  justify-content: center;
}

.selected-option {
  padding: 6px 12px;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.08);
  color: white;
  font-size: 14px;
}

/* Tree option color variations based on meditation type */
.tree-option.visualization-main,
.tree-option.visualization-sub-1,
.tree-option.visualization-sub-2,
.tree-option.visualization-result {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              linear-gradient(to bottom right, #ff4e50, #f9d423);
  border-color: #ff4e50;
}

.tree-option.mindfulness-main,
.tree-option.mindfulness-sub-1,
.tree-option.mindfulness-sub-2,
.tree-option.mindfulness-result {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              linear-gradient(to bottom right, #12c2e9, #c471ed);
  border-color: #12c2e9;
}

.tree-option.sleep-main,
.tree-option.sleep-sub-1,
.tree-option.sleep-sub-2,
.tree-option.sleep-result {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              linear-gradient(to bottom right, #43cea2, #185a9d);
  border-color: #43cea2;
}

.tree-option.breath-main,
.tree-option.breath-sub-1,
.tree-option.breath-sub-2,
.tree-option.breath-result {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              linear-gradient(to bottom right, #a18cd1, #fbc2eb);
  border-color: #a18cd1;
}

.tree-option.affirmations-main,
.tree-option.affirmations-sub-1,
.tree-option.affirmations-sub-2,
.tree-option.affirmations-result {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              linear-gradient(to bottom right, #f093fb, #f5576c);
  border-color: #f093fb;
}

.tree-option.concentration-main,
.tree-option.concentration-sub-1,
.tree-option.concentration-sub-2,
.tree-option.concentration-result {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              linear-gradient(to bottom right, #30cfd0, #330867);
  border-color: #30cfd0;
}

.tree-option.body_scan-main,
.tree-option.body_scan-sub-1,
.tree-option.body_scan-sub-2,
.tree-option.body_scan-result {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              linear-gradient(to bottom right, #48c6ef, #6f86d6);
  border-color: #48c6ef;
}

.tree-option.unguided-main,
.tree-option.unguided-result {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              linear-gradient(to bottom right, #d558c8, #24d292);
  border-color: #d558c8;
}

/* Add this to your main CSS file */

/* Prevent overflow scrolling */
html, body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior: none; /* Prevents bouncing/scrolling past content */
}

/* Fix for iOS Safari 100vh issue */
.meditation-app-container {
  min-height: 100vh; /* fallback */
  min-height: -webkit-fill-available;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Fix for any bottom spacing issues */
.meditation-content {
  padding-bottom: 0;
  margin-bottom: 0;
}

/* Ensure any fixed positioning at bottom doesn't cause scrolling */
.step-navigation {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding-bottom: env(safe-area-inset-bottom, 0);
}